// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basicdata-cz-js": () => import("./../../../src/pages/basicdata.cz.js" /* webpackChunkName: "component---src-pages-basicdata-cz-js" */),
  "component---src-pages-basicdata-de-js": () => import("./../../../src/pages/basicdata.de.js" /* webpackChunkName: "component---src-pages-basicdata-de-js" */),
  "component---src-pages-basicdata-en-js": () => import("./../../../src/pages/basicdata.en.js" /* webpackChunkName: "component---src-pages-basicdata-en-js" */),
  "component---src-pages-basicdata-fr-js": () => import("./../../../src/pages/basicdata.fr.js" /* webpackChunkName: "component---src-pages-basicdata-fr-js" */),
  "component---src-pages-basicdata-js": () => import("./../../../src/pages/basicdata.js" /* webpackChunkName: "component---src-pages-basicdata-js" */),
  "component---src-pages-basicdata-pl-js": () => import("./../../../src/pages/basicdata.pl.js" /* webpackChunkName: "component---src-pages-basicdata-pl-js" */),
  "component---src-pages-basicdata-sk-js": () => import("./../../../src/pages/basicdata.sk.js" /* webpackChunkName: "component---src-pages-basicdata-sk-js" */),
  "component---src-pages-index-cz-js": () => import("./../../../src/pages/index.cz.js" /* webpackChunkName: "component---src-pages-index-cz-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-index-sk-js": () => import("./../../../src/pages/index.sk.js" /* webpackChunkName: "component---src-pages-index-sk-js" */),
  "component---src-pages-shiptos-cz-js": () => import("./../../../src/pages/shiptos.cz.js" /* webpackChunkName: "component---src-pages-shiptos-cz-js" */),
  "component---src-pages-shiptos-de-js": () => import("./../../../src/pages/shiptos.de.js" /* webpackChunkName: "component---src-pages-shiptos-de-js" */),
  "component---src-pages-shiptos-en-js": () => import("./../../../src/pages/shiptos.en.js" /* webpackChunkName: "component---src-pages-shiptos-en-js" */),
  "component---src-pages-shiptos-fr-js": () => import("./../../../src/pages/shiptos.fr.js" /* webpackChunkName: "component---src-pages-shiptos-fr-js" */),
  "component---src-pages-shiptos-js": () => import("./../../../src/pages/shiptos.js" /* webpackChunkName: "component---src-pages-shiptos-js" */),
  "component---src-pages-shiptos-pl-js": () => import("./../../../src/pages/shiptos.pl.js" /* webpackChunkName: "component---src-pages-shiptos-pl-js" */),
  "component---src-pages-shiptos-sk-js": () => import("./../../../src/pages/shiptos.sk.js" /* webpackChunkName: "component---src-pages-shiptos-sk-js" */),
  "component---src-pages-summary-cz-js": () => import("./../../../src/pages/summary.cz.js" /* webpackChunkName: "component---src-pages-summary-cz-js" */),
  "component---src-pages-summary-de-js": () => import("./../../../src/pages/summary.de.js" /* webpackChunkName: "component---src-pages-summary-de-js" */),
  "component---src-pages-summary-en-js": () => import("./../../../src/pages/summary.en.js" /* webpackChunkName: "component---src-pages-summary-en-js" */),
  "component---src-pages-summary-fr-js": () => import("./../../../src/pages/summary.fr.js" /* webpackChunkName: "component---src-pages-summary-fr-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */),
  "component---src-pages-summary-pl-js": () => import("./../../../src/pages/summary.pl.js" /* webpackChunkName: "component---src-pages-summary-pl-js" */),
  "component---src-pages-summary-sk-js": () => import("./../../../src/pages/summary.sk.js" /* webpackChunkName: "component---src-pages-summary-sk-js" */),
  "component---src-pages-survey-cz-js": () => import("./../../../src/pages/survey.cz.js" /* webpackChunkName: "component---src-pages-survey-cz-js" */),
  "component---src-pages-survey-de-js": () => import("./../../../src/pages/survey.de.js" /* webpackChunkName: "component---src-pages-survey-de-js" */),
  "component---src-pages-survey-en-js": () => import("./../../../src/pages/survey.en.js" /* webpackChunkName: "component---src-pages-survey-en-js" */),
  "component---src-pages-survey-fr-js": () => import("./../../../src/pages/survey.fr.js" /* webpackChunkName: "component---src-pages-survey-fr-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-survey-pl-js": () => import("./../../../src/pages/survey.pl.js" /* webpackChunkName: "component---src-pages-survey-pl-js" */),
  "component---src-pages-survey-sk-js": () => import("./../../../src/pages/survey.sk.js" /* webpackChunkName: "component---src-pages-survey-sk-js" */),
  "component---src-pages-thankyou-cz-js": () => import("./../../../src/pages/thankyou.cz.js" /* webpackChunkName: "component---src-pages-thankyou-cz-js" */),
  "component---src-pages-thankyou-de-js": () => import("./../../../src/pages/thankyou.de.js" /* webpackChunkName: "component---src-pages-thankyou-de-js" */),
  "component---src-pages-thankyou-en-js": () => import("./../../../src/pages/thankyou.en.js" /* webpackChunkName: "component---src-pages-thankyou-en-js" */),
  "component---src-pages-thankyou-fr-js": () => import("./../../../src/pages/thankyou.fr.js" /* webpackChunkName: "component---src-pages-thankyou-fr-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-thankyou-pl-js": () => import("./../../../src/pages/thankyou.pl.js" /* webpackChunkName: "component---src-pages-thankyou-pl-js" */),
  "component---src-pages-thankyou-sk-js": () => import("./../../../src/pages/thankyou.sk.js" /* webpackChunkName: "component---src-pages-thankyou-sk-js" */)
}

