import Link from "../components/Link"
import PropTypes from "prop-types"
import { Heading } from "evergreen-ui"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#004EA8`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Heading size={900}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </Heading>
        <img
          width="300"
          alt="Goodyear logo"
          src="/goodyear.svg"
          style={{ marginBottom: 0 }}
        />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
