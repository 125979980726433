import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

export default ({ to, children, ...props }) => {
  const { i18n } = useTranslation()
  const prefixedLink = `/${i18n.language}/${to}`
  return (
    <Link {...props} to={prefixedLink}>
      {children}
    </Link>
  )
}
