import React, { useEffect } from "react"
import { fetchDealerInfo } from "../services/dealerService"

export const initialState = {
  dealerInfo: {
    soldto: "",
    name: "",
    phoneNumber: "",
    address: {},
    shiptos: [],
    language: "",
    registrationNumber: "",
    goodyearShop: false,
    multibrandShop: false,
    retailShop: false,
  },
  confirmation: false,
  loading: false,
  dealerToken: "",
  step: 0,
  steps: [
    {
      heading: "",
      description: "",
      options: [{ name: "", checked: false }],
    },
  ],
  disabledShiptos: [],
  error: "",
}

export const DealerContext = React.createContext({
  data: initialState,
  init: () => {},
  updateDealer: (values) => {},
  updateShiptos: (values) => {},
  updateSecurityOption: (currentStepKey, index) => {},
  setStep: (step) => {},
  disableShipto: (index) => {},
  enableShipto: (index) => {},
  initSecurityOptions: (options) => {},
})

// from https://davidwalsh.name/query-string-javascript
function getUrlParameter(name) {
  name = name.replace("/[[]/", "\\[").replace(/[\]]/, "\\]")
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
  var results = regex.exec(window.location.search)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "))
}

function saveState(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem("state", serializedState)
  } catch (error) {
    console.warn("Could not save state to localStorage", error)
  }
}

function loadState() {
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) {
      return initialState
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return initialState
  }
}

function deleteState() {
  localStorage.removeItem("state")
}

export const DealerProvider = ({ children }) => {
  const [state, setState] = React.useState(loadState())

  useEffect(() => {
    saveState(state)
  }, [state])

  useEffect(() => {
    const fetchData = async (token) => {
      try {
        setState((s) => ({ ...s, loading: true }))
        const data = await fetchDealerInfo(token)
        if (data !== null && data.error === undefined) {
          setState((s) => ({
            ...s,
            dealerInfo: data,
            dealerToken: token,
            loading: false,
            error: "",
          }))
        } else {
          setState((s) => ({
            ...s,
            loading: false,
            error: (data && data.error) || "",
          }))
        }
      } catch (e) {
        console.error(`Error fetching dealer data: ${e}`)
      }
    }

    const dealerToken = getUrlParameter("token")
    if (dealerToken !== "" || process.env.MOCKING === "true") {
      fetchData(dealerToken)
    }
  }, [state.dealerToken])

  function initDealerProvider() {
    deleteState()
    setState({ ...initialState })
  }

  function updateDealerInfo(values) {
    let newDealerInfo = { ...state.dealerInfo }
    newDealerInfo.name = values.dealerName
    newDealerInfo.address.phone = values.dealerPhoneNumber
    newDealerInfo.address.street = values.dealerStreet
    newDealerInfo.address.streetNumber = values.dealerStreetNumber
    newDealerInfo.address.postalCode = values.dealerPostalCode
    newDealerInfo.address.town = values.dealerTown
    newDealerInfo.registrationNumber = values.dealerRegistrationNumber

    setState({
      ...state,
      dealerInfo: newDealerInfo,
      confirmation: values.confirmation,
    })
  }

  function updateShiptos(values) {
    let newDealerInfo = { ...state.dealerInfo }
    newDealerInfo.shiptos = values.shiptos

    setState({ ...state, dealerInfo: newDealerInfo })
  }

  function updateSecurityOption(currentStepIndex, index) {
    let newSecurityOptions = [...state.steps]
    newSecurityOptions[currentStepIndex].options[index].checked =
      !newSecurityOptions[currentStepIndex].options[index].checked

    setState({ ...state, steps: newSecurityOptions })
  }

  function setStep(step) {
    setState({ ...state, step: step })
  }

  function disableShipto(index) {
    let newDisabledShiptos = state.disabledShiptos
    newDisabledShiptos.push(index)
    console.log(newDisabledShiptos)

    setState({ ...state, disabledShiptos: newDisabledShiptos })
  }

  function enableShipto(index) {
    let newDisabledShiptos = state.disabledShiptos
    newDisabledShiptos.splice(newDisabledShiptos.indexOf(index))
    console.log(newDisabledShiptos)

    setState({ ...state, disabledShiptos: newDisabledShiptos })
  }

  function initSecurityOptions(options) {
    setState({ ...state, steps: options })
  }

  return (
    <DealerContext.Provider
      value={{
        data: state,
        init: () => initDealerProvider(),
        updateDealer: (values) => updateDealerInfo(values),
        updateShiptos: (values) => updateShiptos(values),
        updateSecurityOption: (currentStepKey, index) =>
          updateSecurityOption(currentStepKey, index),
        setStep: (step) => setStep(step),
        disableShipto: (index) => disableShipto(index),
        enableShipto: (index) => enableShipto(index),
        initSecurityOptions: (options) => initSecurityOptions(options),
      }}
    >
      {children}
    </DealerContext.Provider>
  )
}
