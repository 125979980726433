import React from "react"
import { Link, Text } from "evergreen-ui"

const Footer = () => (
  <footer
    style={{
      color: "#999",
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        textAlign: "center",
      }}
    >
      <Text>
        © {new Date().getFullYear()}{" "}
        <Link href="//goodyear.eu">Goodyear Europe</Link>
      </Text>
    </div>
  </footer>
)

export default Footer
