import mockDealerData from "../../static/dealerdata.json"

const mocking = process.env.MOCKING

export function fetchDealerInfo(token) {
  const url = `${process.env.DEALER_SIGNUP_URL_TEST}` + token
  if (mocking === "true") {
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        resolve(mockDealerData)
      }, 1500)
    })
  }
  return fetch(url, {
    method: "get",
    headers: {
      "x-api-key": `${process.env.X_API_KEY}`,
    },
  }).then(
    (response) => response.json(),
    (error) => console.log("An error occurred.", error)
  )
}

export function postDealerRegistration(token, data) {
  const url = `${process.env.DEALER_SIGNUP_URL_TEST}` + token
  return fetch(url, {
    method: "POST",
    headers: {
      "x-api-key": `${process.env.X_API_KEY}`,
    },
    body: JSON.stringify(data),
  }).then(
    (response) => response.json(),
    (error) => console.log("Error while posting dealer registration", error)
  )
}
