import React from "react"
import Layout from "./src/components/layout"
import i18next from "i18next"
import { initReactI18next, I18nextProvider } from "react-i18next"

import messages_en from "./src/locales/messages.en.json"
import messages_de from "./src/locales/messages.de.json"
import messages_pl from "./src/locales/messages.pl.json"
import messages_fr from "./src/locales/messages.fr.json"
import messages_sk from "./src/locales/messages.sk.json"
import messages_cz from "./src/locales/messages.cz.json"

const resources = {
  en: {
    translation: messages_en,
  },
  de: {
    translation: messages_de,
  },
  pl: { translation: messages_pl },
  fr: { translation: messages_fr },
  sk: { translation: messages_sk },
  cz: { translation: messages_cz },
}

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => {
  const i18n = i18next
    .createInstance({
      lng: props.pageContext.langKey,
      interpolation: { escapeValue: false },
      initImmediate: false,
      resources,
    })
    .use(initReactI18next)
  i18n.init()

  return (
    <I18nextProvider i18n={i18n}>
      <Layout {...props}>{element}</Layout>
    </I18nextProvider>
  )
}

export default wrapPageElement
