/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"
import "./layout.css"

const Layout = ({ children, pageContext: { langKey } }) => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t("Installer Management")} />
      <Header siteTitle={t("Installer Management")} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
